import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { ColCard, Row, gridStyle } from 'utils/formStyles';
import { addCreatedUpdatedBy, addUpdatedBy, deepEqual } from 'utils/functions';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import RMSFormDivider from 'components/RMSFormDivider';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import PlaceLookup from 'components/RMSPlaceLookup';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import RMSConfirmationModal from 'components/RMSModals/RMSConfirmationModal';
import FormSpinner from 'components/FormSpinner';

import { getService } from 'reducers/service';
import { formatSaveDate } from 'reducers/TimeReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { closeAddWarrant } from 'reducers/PersonDialogsReducer';
import { notify } from '../../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  item2: gridStyle('100%', 800),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  closeButton: { color: theme.button.color },
  topRowMargin: {
    marginTop: theme.spacing(4),
  },
  visible: {
    visibility: 'visible',
  },
  divContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divItem: {
    margin: '5px',
  },
}));

const initObj = {
  FullName: '',
  FirstName: '',
  MiddleName: '',
  LastName: '',
  Suffix: '',
  Prefix: '',
  NickName: '',
  DOB: null,
  ptsPersonID: null,
  WarrantType: '',
  HeldByID: null,
  IssuedDate: new Date(),
  ptsStatuteID: null,
  IssueAgencyID: null,
  ptsIncidentID: null,
  IncidentID: null,
  Court: '',
  BondDate: null,
  IsBondAllowed: false,
  BondAmount: '',
  BondType: '',
  AmountPaid: null,
  DispositionDate: new Date(),
  FineAmount: null,
  WarrantClass: '',
  ArrestDate: null,
  DocketNumber: '',
  IsOnHold: false,
  OffenseDate: null,
  CountQty: null,
  ServedByDate: null,
  AffidavitText: '',
  Notes: '',
  ExpirationDate: null,
  IsDocketCreated: false,
  IsClearedByArrestDocketNotCreated: false,
  Zone: '',
  DispositionIntCode: null,
  UserWarrantID: null,
  ptsWarrantID: null,
  WarrantID: null,
  LegacyConversionNotes: '',
  PrintLabel: '',
  CourtDate: null,
  DispositionCode: '',
  IsActive: true,
};

function WarrantTab(props) {
  const { type = 'add', ptsWarrantID = null, isCloseClick = false, setIsCloseClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const service = getService('rms-warrant');

  const [warrant, setWarrant] = useState(initObj);
  const [oldObj, setOldObj] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [person, setPerson] = useState({
    SSN: '',
    OLN: '',
    State: '',
    GeneralAppearance: '',
    Race: '',
    Sex: '',
    Ethnicity: '',
    Height: '',
    Weight: '',
    HairColor: '',
    EyeColor: '',
    Handed: '',
    BloodType: '',
    HairLength: '',
    HairStyle: '',
  });
  const [warrantFormState, setWarrantFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
    changed: false,
  });
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const dictionary = useSelector((state) => state.dictionary);
  const {
    codeNamePrefixes,
    codeNameSuffixes,
    codeStates,
    codeRace,
    codeSex,
    codeEthnicity,
    codeHairColor,
    codeEyeColor,
    codeHanded,
    codeBloodType,
    codeHairLength,
    codeHairStyle,
    Agencies,
    codeWarrantClasses,
    codeBondTypes,
    codeWarrantDispositions,
    codeZones,
    Citation_Court,
    ptsStatute,
  } = dictionary;

  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scroll({ top: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    setOldObj(initObj);
  }, []);

  useEffect(() => {
    if (
      warrant.ptsPersonID &&
      warrant.IssueAgencyID &&
      warrant.DispositionIntCode &&
      warrant.ptsStatuteID
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [warrant]);

  useEffect(() => {
    if (type === 'edit' && ptsWarrantID) {
      service.get(ptsWarrantID).then((res) => {
        const responseItem = res[0]; // Assuming you're working with the first item in the response array

        // Create a new object with the desired structure
        const updatedObject = {
          FullName: responseItem.FullName,
          FirstName: responseItem.FirstName,
          MiddleName: responseItem.MiddleName,
          LastName: responseItem.LastName,
          Suffix: responseItem.Suffix,
          Prefix: responseItem.Prefix,
          NickName: responseItem.NickName,
          DOB: null, // Set the default values for other fields
          // Continue setting other fields...
        };

        // Now, set the state with the updated object
        setWarrant(responseItem);
      });
    }
  }, [ptsWarrantID]);

  useEffect(() => {
    if (isCloseClick) close();
  }, [isCloseClick]);

  const onClickCancel = () => {
    setOpenConfirmation(false);
    setIsCloseClick(false);
  };

  const onClickNo = () => {
    setOpenConfirmation(false);
    props.closeAddWarrant();
  };

  const handleUnsavedData = () => {
    save();
    setOpenConfirmation(false);
  };

  const handleOffenseStatuteChange = (value) => {
    onChange('ptsStatuteID', value?.ptsStatuteID);
    onChange('AffidavitText', value?.AffidavitText);
    onChange('BondAmount', value?.BondAmount);
  };

  const onChange = (key = '', value = '') => {
    if (key) {
      setWarrant((prevWarrant) => {
        return { ...prevWarrant, [key]: value };
      });
    }
  };

  const onPersonChange = (key = '', value = '') => {
    if (key) {
      setPerson({ ...person, [key]: value });
    }
  };

  const setPersonData = (data) => {
    setWarrant({
      ...warrant,
      FirstName: data?.FirstName || null,
      MiddleName: data?.MiddleName || null,
      FullName: data?.FullName || null,
      LastName: data?.LastName || null,
      Suffix: data?.Suffix || null,
      Prefix: data?.Prefix || null,
      NickName: data?.NickName || null,
      DOB: data?.BirthDate || null,
      ptsPersonID: data?.ptsPersonID || null,
    });

    setPerson({
      SSN: data?.SSN || null,
      OLN: data?.OLN || null,
      State: data?.State || null,
      GeneralAppearance: data?.GeneralAppearance || null,
      Race: data?.Race || null,
      Sex: data?.Sex || null,
      Height: data?.Height || null,
      Weight: data?.Weight || null,
      Ethnicity: data?.Ethnicity || null,
      HairColor: data?.HairColor || null,
      EyeColor: data?.EyeColor || null,
      Handed: data?.Handed || null,
      BloodType: data?.BloodType || null,
      HairLength: data?.HairLength || null,
      HairStyle: data?.HairStyle || null,
    });
  };

  const save = () => {
    setShowSpinner(true);

    if (type === 'edit') {
      const updateData = addUpdatedBy(warrant);
      service
        .patch(warrant.ptsWarrantID, { type: 'warrant', data: updateData })
        .then((res) => {
          props.notifyDataUpdate({
            type: 'Warrant',
            data: {},
          });

          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);

          dispatch(notify('Warrant could not be updated!', 'error'));
          console.log('Warrant update error: ', error);
        });
    } else {
      const createData = addCreatedUpdatedBy(warrant);
      service
        .create({ type: 'warrant', data: createData })
        .then((res) => {
          props.notifyDataUpdate({
            type: 'Warrant',
            data: {},
          });
          props.closeAddWarrant();

          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);

          dispatch(notify('Warrant could not be created!', 'error'));
          console.log('Warrant create error: ', error);
        });
    }
  };

  const close = () => {
    const isEqual = deepEqual(oldObj, warrant);
    if (!isEqual) {
      setOpenConfirmation(true);
      return;
    }
    props.closeAddWarrant();
  };

  const renderPersonSearch = () => {
    return (
      <PersonLookup2
        onChange={(value) => setPersonData(value)}
        label="Person"
        className={classes.item2}
        ptsPersonID={warrant.ptsPersonID}
        error={!warrant.ptsPersonID}
        helperText={!warrant.ptsPersonID && ' This Field Is Required'}
        disabled={type === 'edit'}
        getPersonData={false}
      />
    );
  };

  const renderPrefix = () => {
    const value = codeNamePrefixes.find((item) => item.Code === warrant.Prefix) || null;
    return (
      <RMSDictionary
        options="codeNamePrefixes"
        className={classes.item2}
        disabled
        onChange={(e, value) => onChange('Prefix', value)}
        value={value}
        // formType={personId ? 'edit' : 'add'}
        label="Prefix"
        // error={error}
      />
    );
  };

  const renderFirstName = () => {
    return (
      <TextField
        label="First Name"
        variant="outlined"
        disabled
        onChange={(e) => {
          onChange('FirstName', e.target.value);
        }}
        value={warrant.FirstName}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderMiddleName = () => {
    return (
      <TextField
        label="Middle Name"
        variant="outlined"
        disabled
        onChange={(e) => {
          onChange('MiddleName', e.target.value);
        }}
        value={warrant.MiddleName}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderLastName = () => {
    return (
      <TextField
        label="Last Name"
        variant="outlined"
        disabled
        onChange={(e) => {
          onChange('LastName', e.target.value);
        }}
        value={warrant.LastName}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderSuffix = () => {
    const value = codeNameSuffixes.find((item) => item.Code === warrant.Suffix) || null;
    return (
      <RMSDictionary
        options="codeNameSuffixes"
        className={classes.item2}
        disabled
        onChange={(e, value) => onChange('Suffix', value)}
        value={value}
        label="Suffix"
      />
    );
  };

  const renderNickName = () => {
    return (
      <TextField
        label="Nick Name"
        variant="outlined"
        onChange={(e) => {
          onChange('NickName', e.target.value);
        }}
        disabled
        value={warrant.NickName}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderSsn = () => {
    return (
      <TextField
        label="SSN"
        variant="outlined"
        disabled
        onChange={(e) => onPersonChange('SSN', e.target.value)}
        value={person.SSN}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderOln = () => {
    return (
      <TextField
        label="OLN"
        variant="outlined"
        disabled
        onChange={(e) => onPersonChange('OLN', e.target.value)}
        value={person.OLN}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderState = () => {
    const value = codeStates.find((item) => item.Code === person.State) || null;
    return (
      <RMSDictionary
        options="codeStates"
        className={classes.item2}
        disabled
        onChange={(e, value) => onPersonChange('State', value)}
        value={value}
        label="State"
      />
    );
  };

  const renderGeneralAppearance = () => {
    return (
      <TextField
        label="General Appearance"
        variant="outlined"
        disabled
        onChange={(e) => onPersonChange('GeneralAppearance', e.target.value)}
        value={person.GeneralAppearance}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderDOB = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Birth Date"
          value={warrant.DOB}
          disabled
          onChange={(date) => onChange('DOB', date)}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderRace = () => {
    const value = codeRace.find((item) => item.Code === person.Race);
    return (
      <RMSDictionary
        options="codeRace"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('Race', value)}
        label="Race"
      />
    );
  };

  const renderSex = () => {
    const value = codeSex.find((item) => item.Code === person.Sex);
    return (
      <RMSDictionary
        options="codeSex"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('Sex', value)}
        label="Sex"
      />
    );
  };

  const renderHeight = () => {
    return (
      <TextField
        label="Height"
        variant="outlined"
        disabled
        onChange={(e) => {
          onPersonChange('Height', e.target.value);
        }}
        value={person.Height}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderWeight = () => {
    return (
      <TextField
        label="Weight"
        variant="outlined"
        disabled
        onChange={(e) => onPersonChange('Weight', e.target.value)}
        value={person.Weight}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderEthnicity = () => {
    const value = codeEthnicity.find((item) => item.Code === person.Ethnicity);
    return (
      <RMSDictionary
        options="codeEthnicity"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('Ethnicity', value)}
        label="Ethnicity"
      />
    );
  };

  const renderHairColor = () => {
    const value = codeHairColor.find((item) => item.Code === person.HairColor);
    return (
      <RMSDictionary
        options="codeHairColor"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('HairColor', value)}
        label="Hair Color"
      />
    );
  };

  const renderEyeColor = () => {
    const value = codeEyeColor.find((item) => item.Code === person.EyeColor);
    return (
      <RMSDictionary
        options="codeEyeColor"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('EyeColor', value)}
        label="Eye Color"
      />
    );
  };

  const renderHanded = () => {
    const value = codeHanded.find((item) => item.Code === person.Handed);
    return (
      <RMSDictionary
        options="codeHanded"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('Handed', value)}
        label="Handed"
      />
    );
  };

  const renderBloodType = () => {
    const value = codeBloodType.find((item) => item.Code === person.BloodType);
    return (
      <RMSDictionary
        options="codeBloodType"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('BloodType', value)}
        label="Blood Type"
      />
    );
  };

  const renderHairLength = () => {
    const value = codeHairLength.find((item) => item.Code === person.HairLength);
    return (
      <RMSDictionary
        options="codeHairLength"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('HairLength', value)}
        label="Hair Length"
      />
    );
  };

  const renderHairStyle = () => {
    const value = codeHairStyle.find((item) => item.Code === person.HairStyle);
    return (
      <RMSDictionary
        options="codeHairStyle"
        className={classes.item2}
        value={value}
        disabled
        onChange={(e, value) => onPersonChange('HairStyle', value)}
        label="Hair Style"
      />
    );
  };

  const renderWarrantID = () => {
    return (
      <TextField
        label="User Warrant ID"
        variant="outlined"
        onChange={(e) => {
          onChange('UserWarrantID', e.target.value);
        }}
        value={warrant.UserWarrantID}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderIssueAgency = () => {
    const value = Agencies.find((item) => item.AgencyID === warrant.IssueAgencyID) || null;
    return (
      <RMSDictionary
        options="Agencies"
        value={value}
        className={classes.item2}
        onChange={(e, value) => onChange('IssueAgencyID', value?.AgencyID)}
        label="Issue Agency"
        error={!warrant.IssueAgencyID && ' This Field Is Required'}
      />
    );
  };

  const renderIssueDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Issue Date"
          value={warrant.IssuedDate}
          onChange={(date) => onChange('IssuedDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderWarrantClass = () => {
    const value = codeWarrantClasses.find((item) => item.Code === warrant.WarrantClass) || null;
    return (
      <RMSDictionary
        options="codeWarrantClasses"
        className={classes.item2}
        onChange={(e, value) => onChange('WarrantClass', value?.Code)}
        value={value}
        label="Warrant Class"
      />
    );
  };

  const renderDispositionCode = () => {
    const handleChange = (e, value) => {
      onChange('DispositionIntCode', value?.IntCode);
      onChange('DispositionCode', value?.Code);
    };
    return (
      <RMSAutoComplete
        size="small"
        autoComplete
        autoHighlight
        id="combo-code-warrant-dispositions"
        options={codeWarrantDispositions}
        serviceName="codeWarrantDispositions"
        value={warrant?.DispositionCode}
        label="Dispositions"
        variant="outlined"
        title="Code"
        formType={'add'}
        stateHolder={warrantFormState}
        setStateHolder={setWarrantFormState}
        setField={(event, newValue) => handleChange(event, newValue)}
        stateHolderAttribute="DispositionCode"
        error={!warrant.DispositionIntCode && ' This Field Is Required'}
      />
    );
  };

  const renderDispositionDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Disposition Date"
          value={warrant.DispositionDate}
          onChange={(date) => onChange('DispositionDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderBondAmount = () => {
    return (
      <TextField
        label="Bond Amount"
        variant="outlined"
        onChange={(e) => {
          onChange('BondAmount', e.target.value);
        }}
        value={warrant.BondAmount}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderBondType = () => {
    const value = codeBondTypes.find((item) => item.Code === warrant.BondType) || null;
    return (
      <RMSDictionary
        options="codeBondTypes"
        className={classes.item2}
        onChange={(e, value) => onChange('BondType', value?.Code)}
        value={value}
        label="Bond Type"
      />
    );
  };

  const renderFineAmount = () => {
    return (
      <TextField
        label="Fine Amount"
        variant="outlined"
        onChange={(e) => {
          onChange('FineAmount', e.target.value);
        }}
        value={warrant.FineAmount}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderAmountPaid = () => {
    return (
      <TextField
        label="Amount Paid"
        variant="outlined"
        onChange={(e) => {
          onChange('AmountPaid', e.target.value);
        }}
        value={warrant.AmountPaid}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderHeldBy = () => {
    return (
      <PlaceLookup
        className={classes.item2}
        onChange={(value) => onChange('HeldByID', value?.ptsPlaceID)}
        ptsPlaceID={warrant.HeldByID}
        label="Held By"
      />
    );
  };

  const renderCourt = () => {
    const value = Citation_Court.find((item) => item.Court_Name === warrant.Court) || null;
    return (
      <RMSDictionary
        options="Citation_Court"
        value={value}
        className={classes.item2}
        onChange={(e, value) => onChange('Court', value?.Court_Name)}
        label="Court"
      />
    );
  };

  const renderCourtDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Court Date"
          value={warrant.CourtDate}
          onChange={(date) => onChange('CourtDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDocketNumber = () => {
    return (
      <TextField
        label="Docket Number"
        variant="outlined"
        onChange={(e) => {
          onChange('DocketNumber', e.target.value);
        }}
        value={warrant.DocketNumber}
        className={classes.item2}
        type="text"
        size="small"
      />
    );
  };

  const renderServiceDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Service Date"
          value={warrant.ServedByDate}
          onChange={(date) => onChange('ServedByDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderIncidentID = () => {
    return (
      <TextField
        label="Incident ID"
        variant="outlined"
        onChange={(e) => {
          onChange('IncidentID', e.target.value);
        }}
        value={warrant.IncidentID}
        className={classes.item2}
        size="small"
      />
    );
  };

  const renderZone = () => {
    const value = codeZones.find((item) => item.ZoneCode === warrant.Zone) || null;
    return (
      <RMSDictionary
        options="codeZones"
        className={classes.item2}
        onChange={(e, value) => onChange('Zone', value?.ZoneCode)}
        value={value}
        label="Zone"
      />
    );
  };

  const renderExpirationDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Expiration Date"
          value={warrant.ExpirationDate}
          onChange={(date) => onChange('ExpirationDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderPrintLabel = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.PrintLabel}
            onChange={(e) => onChange('PrintLabel', e.target.checked)}
            name="PrintLabel"
            color="primary"
          />
        }
        label="Print Label"
        className={classes.item2}
      />
    );
  };

  const renderIsOnHold = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsOnHold}
            onChange={(e) => onChange('IsOnHold', e.target.checked)}
            name="IsOnHold"
            color="primary"
          />
        }
        label="Is On Hold"
        className={classes.item2}
      />
    );
  };

  const renderIsClearedByArrestDock = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsClearedByArrestDocketNotCreated}
            onChange={(e) => onChange('IsClearedByArrestDocketNotCreated', e.target.checked)}
            name="IsClearedByArrestDocketNotCreated"
            color="primary"
          />
        }
        label="Cleared by Arrest Docket Not Created"
        className={classes.item2}
      />
    );
  };

  const renderIsDocketCreated = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsDocketCreated}
            onChange={(e) => onChange('IsDocketCreated', e.target.checked)}
            name="IsDocketCreated"
            color="primary"
          />
        }
        label="Docket Created"
        className={classes.item2}
      />
    );
  };

  const renderOffenseStatute = () => {
    const value = ptsStatute.find((item) => item.ptsStatuteID === warrant.ptsStatuteID);
    return (
      <RMSDictionary
        options="ptsStatute"
        className={classes.item2}
        value={value}
        onChange={(e, value) => handleOffenseStatuteChange(value)}
        label="Offense Statute"
        error={!warrant.ptsStatuteID && ' This Field Is Required'}
      />
    );
  };

  const renderOffenseDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Offense Date"
          value={warrant.OffenseDate}
          onChange={(date) => onChange('OffenseDate', date)}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderCountQty = () => {
    return (
      <TextField
        label="Offense Count"
        variant="outlined"
        onChange={(e) => {
          onChange('CountQty', e.target.value);
        }}
        value={warrant.CountQty}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderArrestDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Arrest Date"
          value={warrant.ArrestDate}
          onChange={(date) => onChange('ArrestDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderAffidavitText = () => {
    return (
      <TextField
        label="Affidavit Text"
        variant="outlined"
        onChange={(e) => {
          onChange('AffidavitText', e.target.value);
        }}
        value={warrant.AffidavitText}
        className={classes.item2}
        size="small"
        multiline
        rows={4}
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={(e) => {
          onChange('Notes', e.target.value);
        }}
        value={warrant.Notes}
        className={classes.item2}
        size="small"
        multiline
        rows={4}
      />
    );
  };

  const renderIsBondAllowed = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={warrant.IsBondAllowed}
            onChange={(e) => onChange('IsBondAllowed', e.target.checked)}
            name="IsBondAllowed"
            color="primary"
          />
        }
        label="Bond Allowed"
        className={classes.item2}
      />
    );
  };

  const renderBondDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          isableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Bond Date"
          value={warrant.BondDate}
          onChange={(date) => onChange('BondDate', formatSaveDate(date))}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" onClick={save} disabled={saveDisabled}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <List>
      <div className={classes.actions}></div>
      {showSpinner && <FormSpinner />}
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title={'Person'} />
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              {renderPersonSearch()}
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              {renderPrefix()}
            </Grid>
            <Grid item xs={2}>
              {renderFirstName()}
            </Grid>
            <Grid item xs={2}>
              {renderMiddleName()}
            </Grid>
            <Grid item xs={2}>
              {renderLastName()}
            </Grid>
            <Grid item xs={2}>
              {renderSuffix()}
            </Grid>
            <Grid item xs={2}>
              {renderNickName()}
            </Grid>
          </Grid>
          <ColCard minWidth={500}>
            <RMSFormDivider title={'Credentials'} />
            <Grid container xs={12} spacing={1}>
              <Grid item xs={4}>
                {renderSsn()}
              </Grid>
              <Grid item xs={4}>
                {renderOln()}
              </Grid>
              <Grid item xs={4}>
                {renderState()}
              </Grid>
            </Grid>
          </ColCard>
          <ColCard minWidth={500}>
            <RMSFormDivider title={'General Details'} />
            <Grid container xs={12} spacing={1}>
              <Grid item xs={2}>
                {renderGeneralAppearance()}
              </Grid>
              <Grid item xs={2}>
                {renderDOB()}
              </Grid>
              <Grid item xs={2}>
                {renderRace()}
              </Grid>
              <Grid item xs={2}>
                {renderSex()}
              </Grid>
              <Grid item xs={2}>
                {renderHeight()}
              </Grid>
              <Grid item xs={2}>
                {renderWeight()}
              </Grid>
            </Grid>
          </ColCard>
          <ColCard minWidth={500}>
            <RMSFormDivider title={'Features'} />
            <Grid container xs={12} spacing={1}>
              <Grid item xs={2}>
                {renderEthnicity()}
              </Grid>
              <Grid item xs={2}>
                {renderEyeColor()}
              </Grid>
              <Grid item xs={2}>
                {renderHanded()}
              </Grid>
              <Grid item xs={2}>
                {renderBloodType()}
              </Grid>
              <Grid item xs={2}>
                {renderHairColor()}
              </Grid>
              <Grid item xs={2} lg={1}>
                {renderHairLength()}
              </Grid>
              <Grid item xs={2} lg={1}>
                {renderHairStyle()}
              </Grid>
            </Grid>
          </ColCard>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title={'Warrant Information'} />
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              {renderWarrantID()}
            </Grid>
            <Grid item xs={2}>
              {renderIssueAgency()}
            </Grid>
            <Grid item xs={2}>
              {renderIssueDate()}
            </Grid>
            <Grid item xs={2}>
              {renderWarrantClass()}
            </Grid>
            <Grid item xs={2}>
              {renderDispositionCode()}
            </Grid>
            <Grid item xs={2}>
              {renderDispositionDate()}
            </Grid>
            <Grid item xs={2}>
              {renderFineAmount()}
            </Grid>
            <Grid item xs={2}>
              {renderAmountPaid()}
            </Grid>
            <Grid item xs={2}>
              {renderHeldBy()}
            </Grid>
            <Grid item xs={2}>
              {renderCourt()}
            </Grid>
            <Grid item xs={2}>
              {renderCourtDate()}
            </Grid>
            <Grid item xs={2}>
              {renderDocketNumber()}
            </Grid>
            <Grid item xs={2}>
              {renderServiceDate()}
            </Grid>
            <Grid item xs={2}>
              {renderIncidentID()}
            </Grid>
            <Grid item xs={2}>
              {renderZone()}
            </Grid>
            <Grid item xs={2}>
              {renderExpirationDate()}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              {renderPrintLabel()}
            </Grid>
            <Grid item xs={2}>
              {renderIsOnHold()}
            </Grid>
            <Grid item xs={2}>
              {renderIsClearedByArrestDock()}
            </Grid>
            <Grid item xs={2}>
              {renderIsDocketCreated()}
            </Grid>
          </Grid>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title={'Offense Information'} />
          <Grid container xs={12} spacing={1}>
            <Grid item xs={3}>
              {renderOffenseStatute()}
            </Grid>
            <Grid item xs={3}>
              {renderOffenseDate()}
            </Grid>
            <Grid item xs={3}>
              {renderCountQty()}
            </Grid>
            <Grid item xs={3}>
              {renderArrestDate()}
            </Grid>
          </Grid>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title={'Bond Information'} />
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              {renderIsBondAllowed()}
            </Grid>
            <Grid item xs={2}>
              {renderBondType()}
            </Grid>
            <Grid item xs={2}>
              {renderBondAmount()}
            </Grid>
            <Grid item xs={2}>
              {renderBondDate()}
            </Grid>
          </Grid>
        </ColCard>
      </Row>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={6}>
          {renderAffidavitText()}
        </Grid>
        <Grid item xs={6}>
          {renderNotes()}
        </Grid>
      </Grid>
      <div className={classes.actions}>{renderActions()}</div>

      <RMSConfirmationModal
        title="You have unsaved changes in this form!"
        text="Do you want to save changes?"
        saveBtnName="Yes"
        btnName="No"
        noBtnName="Cancel"
        open={openConfirmation}
        closeDialog={onClickCancel}
        handleSubmit={onClickNo}
        handleModalSave={handleUnsavedData}
        btnDisabled={saveDisabled}
      />
    </List>
  );
}

export default connect(null, { notifyDataUpdate, closeAddWarrant })(WarrantTab);
