import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, InputAdornment, TextField, Tooltip } from '@material-ui/core';

import formStyles, { gridStyle } from 'utils/formStyles';
import { getService } from 'reducers/service';
import { closeAddAdditionalOffense } from 'reducers/PersonDialogsReducer';
import { connect, useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { addCreatedUpdatedBy } from 'utils/functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TbClockExclamation } from 'react-icons/tb';
import { IconButton } from '@mui/material';
import { MdAutoDelete } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 220),
  lookup: gridStyle(300, 550),
  wrap: {
    display: 'flex',
    alignItems: 'center',
    spacing: theme.spacing(3),
  },
  autocompleteContainer: {
    flexGrow: 1, // Autocomplete takes most of the space
    marginRight: theme.spacing(1), // Add margin if needed
    width: '100%',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
  closeButton: { color: theme.button.color },
}));

const AdditionalOffensesDialog = (props) => {
  const classes = useStyles();
  const { ptsWarrantID, setAddForm, type = 'add', primaryOffense } = props;
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState({});
  const [selection, setSelection] = useState(null);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);
  const serviceWarrant = getService('rms-warrant');

  useEffect(() => {
    let sortedArray = ptsStatute
      ?.filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray?.forEach((s) => {
      let fbObj = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID);

      s.FBICode = fbObj?.FBICode;
      s.FBIDescription = fbObj?.Description;
      s.CrimeAgainst = fbObj?.CrimeAgainst;
      s.AggravatedAssaultOrHomicide = fbObj?.AggravatedAssaultOrHomicide;
      s.BiasMotivation = fbObj?.BiasMotivation;
      s.CargoTheft = fbObj?.CargoTheft;
      s.CriminalActivityOrGangInfo = fbObj?.CriminalActivityOrGangInfo;
      s.InjuryType = fbObj?.InjuryType;
      s.JustifiableHomicide = fbObj?.JustifiableHomicide;
      s.LocationType = fbObj?.LocationType;
      s.MethodOfEntry = fbObj?.MethodOfEntry;
      s.OffenderSuspectedOfUsing = fbObj?.OffenderSuspectedOfUsing;
      s.PropertyLossType = fbObj?.PropertyLossType;
      s.TypeWeaponOrForceInvolved = fbObj?.TypeWeaponOrForceInvolved;
      s.AllowedCriminalActivityOrGangInfo = fbObj?.AllowedCriminalActivityOrGangInfo;
      s.StructureEntered = fbObj?.StructureEntered;
      s.OfficerActivity = fbObj?.OfficerActivity;
      s.NegligentManslaughter = fbObj?.NegligentManslaughter;

      arr.push(s);
    });
    const processOptions = arr.filter((item) => {
      const { EnactmentDate, IsActive, IsDeleted } = item;
      if (
        IsActive &&
        IsDeleted === false &&
        EnactmentDate &&
        new Date(EnactmentDate) <= new Date()
      ) {
        return true;
      }
      return false;
    });
    setRows(processOptions);
  }, [ptsStatute, ptsFBICode]);
  useEffect(() => {
    if (type === 'edit') setValue(primaryOffense);
  }, [type]);

  // const { data } = props;

  const save = async () => {
    try {
      if (type === 'add') {
        const filterArr = selection.map((item) => item?.ptsStatuteID);
        const data = addCreatedUpdatedBy({ ptsWarrantID, statuteIDs: filterArr });
        await serviceWarrant.create({
          type: 'warrant_additional_offense',
          data: data,
        });
        props.notifyDataUpdate({ type: 'warrants-offenses' });
      } else {
        console.log('value ', value);
        await serviceWarrant.patch(ptsWarrantID, {
          type: 'warrant_primary_offense',
          data: { ptsWarrantID, ptsStatuteID: value.ptsStatuteID },
        });
        props.notifyDataUpdate({ type: 'single-warrant' });
      }

      props.notify('Offenses Saved', 'Success');
      close();
    } catch (err) {
      console.log(err);
      props.notify(err.message, 'Error');
    }
  };
  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={
            (type === 'add' && !selection) || (type === 'edit' && (!value || props.viewOnly))
          }>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const close = () => {
    // props.closeAddAdditionalOffense();
    setAddForm(false);
  };

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} md={9}>
        {type === 'add' ? (
          <Autocomplete
            multiple
            id="tags-outlined"
            options={rows}
            fullWidth
            size="small"
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode} (${option.FBIDescription})`;
            }}
            style={{ Width: '100%' }}
            filterSelectedOptions
            renderOption={(option) => (
              <span>
                ${option.Description} | ${option.Code} | ${option.FBICode} (${option.FBIDescription}
                )
                {option.RepealDate && new Date(option.RepealDate) <= new Date() && (
                  <span style={{ marginLeft: '25px' }}>
                    <TbClockExclamation size={20} color="red" />{' '}
                  </span>
                )}
              </span>
            )}
            renderInput={(params) => (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...params}
                  label="Search Statutes and FBI Codes"
                  placeholder="Additional Offenses"
                />
              </Grid>
            )}
            onChange={(event, newValue) => {
              setSelection(newValue);
            }}
          />
        ) : (
          <Autocomplete
            id="tags-outlined"
            value={value}
            options={rows}
            fullWidth
            size="small"
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode} `;
            }}
            style={{ Width: '100%' }}
            filterSelectedOptions
            renderOption={(option) => (
              <span>
                ${option.Description} | ${option.Code} | ${option.FBICode} (${option.FBIDescription}
                )
                {option.RepealDate && new Date(option.RepealDate) <= new Date() && (
                  <span style={{ marginLeft: '25px' }}>
                    <TbClockExclamation size={20} color="red" />{' '}
                  </span>
                )}
              </span>
            )}
            renderInput={(params) => (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...params}
                  label="Search Statutes and FBI Codes"
                  placeholder="Offense Statute"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: value?.IsDeleted ? (
                      <InputAdornment position="start" className="ml-2">
                        <Tooltip title="Deleted Code">
                          <IconButton size="small">
                            <MdAutoDelete size={25} color="red" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
            )}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
        )}
      </Grid>
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '20px',
          paddingBottom: '3px',
          paddingTop: '3px',
        }}>
        {renderActions()}
      </span>
    </Grid>
  );
};

export default connect(null, { closeAddAdditionalOffense, notifyDataUpdate, notify })(
  AdditionalOffensesDialog
);
